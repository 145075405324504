import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Product, shopService } from "../../services/shopService.ts";
import ProductPreview from "../products/ProductPreview.tsx";

const AllSexView = ({collectionId}) => {
  const [collectionProducts, setCollectionProducts] = React.useState<Product[]>();

  React.useEffect(() => {
    shopService.getAllSexBasedProducts(collectionId).then(setCollectionProducts);
  }, [collectionId]);

  return (
    <Row>
      {collectionProducts &&
        collectionProducts.map(
          (p) =>
            p.productImages &&
            p.productImages.length > 0 && (
              <Col span={6}>
                <Link to={`/products/${p.productId}`}>
                  <ProductPreview key={p.productId} product={p} />
                </Link>
              </Col>
            )
        )}
    </Row>
  );
};

export default AllSexView;
